import React, { useState, useEffect } from "react";
import { DEFAULT_DECIMAL_PLACES_TO_DISPLAY } from "../constants";

const AnimatedNumber = ({
  value,
  duration,
  decimalPlacesToDisplay = DEFAULT_DECIMAL_PLACES_TO_DISPLAY, // this number must be the same as the maxDecimals in the converBigIntToDisplayNumber and formatDisplayPrice fns
  isFloatingDecimals = false,
}: {
  value: number;
  duration: number;
  decimalPlacesToDisplay?: number;
  isFloatingDecimals?: boolean;
}) => {
  const [currentValue, setCurrentValue] = useState(value);
  useEffect(() => {
    const startValue = currentValue;
    const startTime = performance.now();

    const updateValue = (currentTime: number) => {
      const elapsedTime = currentTime - startTime;
      const progress = Math.min(elapsedTime / duration, 1); // Keep progress between 0 and 1
      const newValue = Math.floor(startValue + (value - startValue) * progress);
      setCurrentValue(newValue);

      if (progress < 1) {
        requestAnimationFrame(updateValue);
      }
    };

    requestAnimationFrame(updateValue);
  }, [value, duration]); // Trigger animation on value change

  return (
    <span>
      {isFloatingDecimals
        ? currentValue.toString().padStart(decimalPlacesToDisplay, "0")
        : currentValue.toString()}
    </span>
  );
};
export default AnimatedNumber;
