import React from "react";
const ErrorMsg = ({ message }: { message: string }) => {
  return (
    <div className="flex center" style={{ color: "red" }}>
      {message}
    </div>
  );
};

export default ErrorMsg;
