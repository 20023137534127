import React, { useState, useEffect } from "react";
import { default as AnimatedNumber } from "./AnimatedNumber";

const FloatingAnimatedNumber = ({
  wholeNumber,
  decimalWholeNumber,
  duration,
}: {
  wholeNumber: number;
  decimalWholeNumber: number;
  duration: number;
}) => {
  const [values, setValues] = useState({
    wholeNumber: wholeNumber,
    decimalWholeNumber: decimalWholeNumber,
  });

  useEffect(() => {
    setValues({
      wholeNumber,
      decimalWholeNumber,
    });
  }, [wholeNumber, decimalWholeNumber, duration]); // Trigger animation on value change

  return (
    <>
      <AnimatedNumber value={values.wholeNumber} duration={duration} />.
      <AnimatedNumber
        value={values.decimalWholeNumber}
        duration={duration}
        isFloatingDecimals={true}
      />
    </>
  );
};
export default FloatingAnimatedNumber;
