import React, { useEffect, useState } from "react";

import {
  useNotification,
  useConnection,
  useSmartObject,
} from "@eveworld/contexts";
import { Severity } from "@eveworld/types";
import {
  SmartAssemblyInfo,
  EveLoadingAnimation,
  ClickToCopy,
  ErrorNotice,
  ErrorNoticeTypes,
} from "@eveworld/ui-components";
import { abbreviateAddress } from "@eveworld/utils";
import { defaultDescriptions } from "@eveworld/utils/consts";

import AllEntitiesView from "./AllEntitiesView";
import EquippedModules from "./Modules";
import { useQueryParamRedirect } from "../hooks";
import SmartAssemblyActions from "./SmartAssemblyActions";
import SmartGateImage from "../assets/smart-gate.png";
import SmartStorageUnitImage from "../assets/smart-storage-unit.png";
import SmartTurretImage from "../assets/smart-turret.png";
import { useLocation } from "react-router-dom";
import BaseSSU from "./Entities/BaseSSU";
import { deriveEntityView } from "./Entities/utils";
import { setupMud } from "../mud";
import { useEffectOnce } from "../utils";
import { AdminPanel } from "./Entities";

const EntityView = () => {
  const { defaultNetwork, gatewayConfig, walletClient, publicClient } =
    useConnection();
  const { loading, smartAssembly, smartCharacter } = useSmartObject();
  const location = useLocation();
  const { notify, handleClose } = useNotification();
  // useQueryParamRedirect();

  useEffect(() => {
    if (loading) {
      notify({ type: Severity.Info, message: "Loading..." });
    } else {
      handleClose();
    }
  }, [loading]);

  if (!loading && !location.search.includes("smartObjectId")) {
    return <AllEntitiesView />;
  }
  const queryParams = new URLSearchParams(window.location.search);
  const queryParamSmartObjectId = queryParams.get("smartObjectId");
  if (!smartAssembly || smartAssembly?.id !== queryParamSmartObjectId) {
    return (
      <div className="flex flex-col align-center">
        <EveLoadingAnimation position="horizontal">
          <div className="flex flex-row align-center">Loading...</div>
        </EveLoadingAnimation>
      </div>
    );
  }
  const defaultImages: Record<string, string> = {
    SmartStorageUnit: SmartStorageUnitImage,
    SmartTurret: SmartTurretImage,
    SmartGate: SmartGateImage,
  };
  const { user: EntityItem, admin: AdminEntity } = deriveEntityView(
    smartAssembly.assemblyType
  );
  return (
    <>
      {queryParams.get("showAdminPanel") ? (
        <AdminPanel
          smartCharacter={smartCharacter}
          smartAssembly={smartAssembly}
          defaultNetwork={defaultNetwork}
          gatewayConfig={gatewayConfig}
          walletClient={walletClient}
          publicClient={publicClient}
        >
          <div className="Quantum-Container Title">Admin Dashboard</div>
          <AdminEntity
            smartAssemblyId={smartAssembly.id}
            smartCharacter={smartCharacter}
            smartAssembly={smartAssembly}
            defaultNetwork={defaultNetwork}
            gatewayConfig={gatewayConfig}
            walletClient={walletClient}
            publicClient={publicClient}
            tradeAssetDecimals={18} // @todo: fix hardcoded decimals
          />
        </AdminPanel>
      ) : (
        <EntityItem
          smartCharacter={smartCharacter}
          smartAssembly={smartAssembly}
          defaultNetwork={defaultNetwork}
          gatewayConfig={gatewayConfig}
          walletClient={walletClient}
          publicClient={publicClient}
        />
      )}
    </>
  );
};

export default EntityView;
