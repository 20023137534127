import { SmartAssemblyType } from "@eveworld/types/types";
import EveScroll from "@eveworld/ui-components/components/EveScroll";
import { findOwnerByAddress, isOwner } from "@eveworld/utils/utils";
import { WalletClient } from "viem";
import StorageItem from "./StorageItem";
import CapacityView from "./Capacity";
import { useConnection, useWorld } from "@eveworld/contexts";

const UserStorage = ({
  smartAssembly,
  walletClient,
  tradeAssetBalance,
  tradeAssetDecimals,
  tradeAssetTicker,
}: {
  smartAssembly: SmartAssemblyType<"SmartStorageUnit">;
  walletClient: WalletClient;
  tradeAssetBalance: bigint;
  tradeAssetDecimals: number;
  tradeAssetTicker: string;
}): JSX.Element => {
  const inventoryName = "Your Inventory";
  if (!smartAssembly) return <></>;

  const { publicClient, defaultNetwork } = useConnection();
  // const isEntityOwner: boolean = isOwner(
  //   smartAssembly,
  //   walletClient?.account?.address
  // );
  const ephemeralInventoryList =
    smartAssembly.inventory?.ephemeralInventoryList || [];

  const playerInventory = ephemeralInventoryList.find((x) =>
    findOwnerByAddress(x.ownerId, walletClient?.account?.address)
  );

  // If owner, return persistent storage items
  // If player, return own ephemeral storage items
  const inventoryItems = playerInventory?.ephemeralInventoryItems?.filter(
    (item) => {
      return item && item.name;
    }
  );

  const storageCap = playerInventory?.storageCapacity || null;
  const usedCap = playerInventory?.usedCapacity || null;
  const disabledMsg = smartAssembly.isOnline
    ? null
    : "SSU is offline. Must be online to trade.";

  return (
    <div className="flex flex-col w-full">
      <div className="Quantum-Container Title">{`${inventoryName}`}</div>
      <CapacityView
        inventoryName={inventoryName}
        usedCap={usedCap}
        ofCap={storageCap}
      />
      <EveScroll maxHeight="500px" id="smartassembly-inventory">
        <div className="Quantum-Container text-xs flex flex-col !py-4 gap-2 min-h-full">
          {!inventoryItems || inventoryItems.length === 0 ? (
            <div>
              Empty. Add items to the SSU to see them appear here. You may need
              to refresh this app to see them after depositing.
            </div>
          ) : (
            inventoryItems?.map((item, index) => (
              <div
                key={index}
                className="border border-brightquantum gap-1 p-1"
              >
                <StorageItem
                  item={item}
                  walletClient={walletClient}
                  publicClient={publicClient}
                  defaultNetwork={defaultNetwork}
                  assemblyId={BigInt(smartAssembly.id)}
                  type={"sell"}
                  tradeAssetBalance={tradeAssetBalance}
                  tradeAssetTicker={tradeAssetTicker}
                  tradeAssetDecimals={tradeAssetDecimals}
                  disabledMsg={disabledMsg}
                />
              </div>
            ))
          )}
        </div>
      </EveScroll>
    </div>
  );
};

export default UserStorage;
