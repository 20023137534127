import { useState, useEffect, useRef } from "react";
import { default as ForceGraph3D, ForceGraph3DInstance } from "3d-force-graph";
import SpriteText from "three-spritetext";
import { EveButton, EveInput } from "@eveworld/ui-components";
import * as THREE from "three";

import smartGateLocations from "../../assets/smart_gate_locs.json";
import jumpDriveLocations from "../../assets/jump_drive_locs.json";
import { useEffectOnce } from "../../utils/hooks";
import "./canvas.css";

import { Coords, CoordsWithMeta, SimpleCoordsWithMeta } from "./types";
import {
  extractMiddleOfCoords,
  getCoords,
  scaleCoordinatesToRange,
  shiftCoordinatesToCenter,
} from "./utils";

const GalaxyMap = () => {
  const graphRef = useRef(null);
  const graphInstanceRef = useRef<null | ForceGraph3DInstance>(null);
  const [graphData] = useState<{
    nodes: CoordsWithMeta[];
    links: any[];
    type: string;
    nodesMap: { [key: string]: CoordsWithMeta };
  }>(getCoords());
  const [error, setError] = useState<string | null>(null);
  const [focusedNode, setFocusedNode] = useState<CoordsWithMeta | any | null>(
    null
  );

  const deriveCameraPosition = (focusedNode: CoordsWithMeta) => {
    if (focusedNode) {
      const distance = 50;
      const distRatio =
        1 + distance / Math.hypot(focusedNode.x, focusedNode.y, focusedNode.z);
      return {
        x: focusedNode.x * distRatio,
        y: focusedNode.y * distRatio,
        z: focusedNode.z * distRatio,
      };
    }
    return { ...graphData.nodes[0] };
  };
  useEffect(() => {
    const graph = ForceGraph3D()(graphRef.current as any); // Initialize the 3D force graph on the ref
    graphInstanceRef.current = graph;
    console.log(
      "Graph instance",
      graphInstanceRef.current,
      graphData.nodes.length
      // initialFocusedCoords
    );
    if (
      !graphInstanceRef.current ||
      graphData.nodes.length === 0
      // !initialFocusedCoords
    ) {
      return;
    }
    // console.log("initialFocusedCoords", initialFocusedCoords);
    console.time("Graph_Init");
    graph
      .graphData(graphData as any)

      .cooldownTicks(0)
      .width(1048)
      .height(700)
      .nodeAutoColorBy("relationshipsLength")
      .nodeRelSize(0.15)
      .nodeLabel("")
      .nodeResolution(4)
      .linkWidth(0.15)
      .linkLabel("name")
      .nodeThreeObjectExtend(true)
      .nodeThreeObject((node) => {
        const lod = new THREE.LOD();

        // High detail (e.g., a complex sphere)
        const highDetailGeometry = new THREE.SphereGeometry(0.15, 16, 16);
        const highDetailMaterial = new THREE.MeshBasicMaterial({
          color: node.color,
        });
        const highDetailMesh = new THREE.Mesh(
          highDetailGeometry,
          highDetailMaterial
        );
        lod.addLevel(highDetailMesh, 0);

        // Medium detail
        const mediumDetailGeometry = new THREE.SphereGeometry(0.15, 8, 8);
        const mediumDetailMaterial = new THREE.MeshBasicMaterial({
          color: node.color,
        });
        const mediumDetailMesh = new THREE.Mesh(
          mediumDetailGeometry,
          mediumDetailMaterial
        );
        lod.addLevel(mediumDetailMesh, 50);

        const group = new THREE.Group();
        group.add(lod);
        node.selectionSphere = {}; // Placeholder
        return group;
      })
      .onNodeClick((node) => {
        if (graph) {
          graph.graphData().nodes.forEach((n) => {
            if (n.selectionSphere) n.selectionSphere.visible = false;
          });
        }

        setFocusedNode(node);

        if (!node.selectionSphere) {
          const translucentGeometry = new THREE.SphereGeometry(0.3, 64, 64); // Match node scale
          const translucentMaterial = new THREE.MeshBasicMaterial({
            color: "red",
            opacity: 0.3,
            transparent: true,
            depthTest: false, // Always render on top
          });
          const translucentSphere = new THREE.Mesh(
            translucentGeometry,
            translucentMaterial
          );
          translucentSphere.renderOrder = 999; // Ensure it renders on top

          const nodeObject = graph.nodeThreeObject(node); // Retrieve existing node object
          console.log("Node object:", nodeObject);
          console.log("Node object children before add:", nodeObject.children);

          nodeObject.add(translucentSphere); // Attach to the node object
          node.selectionSphere = translucentSphere; // Store for future reference
          console.log("Node object children after add:", nodeObject.children);
        }

        node.selectionSphere.visible = true;
        console.log(
          "Selection sphere visibility:",
          node.selectionSphere.visible
        );
      })
      .cameraPosition(
        deriveCameraPosition(graphData.nodes[0]), // new position
        graphData.nodes[0]
      ) // lookAt ({ x, y, z })) // Adjust camera position
      .enableNodeDrag(false);
    // Attach the graph data
    console.timeEnd("Graph_Init");

    // Clean up on unmount
    return () => {
      graph.pauseAnimation();
      if (graphRef.current) {
        (graphRef.current as any).innerHTML = ""; // Clean up the DOM element
      }
    };
  }, [graphData]);

  useEffect(() => {
    console.log("Focused node", focusedNode);
    if (!focusedNode) return;
    console.log("Graph ref here");
    if (
      !graphRef ||
      graphInstanceRef === null ||
      !graphInstanceRef.current ||
      graphInstanceRef.current === null
    ) {
      console.error("No graph ref found");
    }
    console.log("Graph ref here2", focusedNode);
    (graphInstanceRef.current as ForceGraph3DInstance).cameraPosition(
      deriveCameraPosition(focusedNode), // new position
      focusedNode, // lookAt ({ x, y, z })
      1250 // ms transition duration
    );
    if (focusedNode) {
      console.log(
        "Focused node",
        focusedNode,
        focusedNode.selectionSphere?.uuid
      );
      if (focusedNode.selectionSphere?.uuid) {
        console.log("Focused node sphere", focusedNode.selectionSphere);
        focusedNode.selectionSphere.visible = true;
        console.log("Focused node sphere", focusedNode.selectionSphere);
      }
    }
  }, [focusedNode]);
  return (
    <div className="bg-crude">
      <div className="Quantum-Container Title">{`${graphData.type} Map`}</div>
      <div className="Quantum-Container">
        <span className="text-xs">{`This Map contains all of the systems in the game and their
        ${graphData.type} links (<${graphData.type === "NPC Gate" ? "300" : "5"} Light Years distant). To search for a system, just enter the name of the system below and the map will focus on it.`}</span>
        {/* <EveButton
          typeClass="secondary"
          onClick={() => {
            setgraphData({
              locations:
                graphData.type === "NPC Gate"
                  ? jumpDriveLocations
                  : smartGateLocations,
              type: graphData.type === "NPC Gate" ? "Jump Drive" : "Smart Gate",
            });
          }}
        >
          {`Show ${graphData.type === "NPC Gate" ? "Jump Drive" : "Smart Gate"} Map`}
        </EveButton> */}
        <EveInput
          inputType="string"
          fieldName=""
          defaultValue=""
          placeholder="System name - (e.g. Cydias)"
          onChange={(name) => {
            if (!name) {
              setError(null);
              return;
            }
            const node = graphData.nodesMap[name.toString().toLowerCase()];
            if (!node || !node.x || !node.y || !node.z) {
              setError(`No node found for system name: ${name}`);
              return;
            }
            setError(null);
            setFocusedNode(node);
          }}
        />
        {error !== null && <div>Error searching for system. {error}</div>}
      </div>
      <div className="Quantum-Container">
        {graphRef ? (
          <div
            ref={graphRef as any}
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              position: "relative",
            }}
          />
        ) : (
          <div>Loading...</div>
        )}
      </div>
    </div>
  );
};

export default GalaxyMap;
