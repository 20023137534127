import EveLinearBar from "@eveworld/ui-components/components/EveLinearBar";
import { formatM3 } from "@eveworld/utils/utils";
import React from "react";

const CapacityView = ({
  inventoryName,
  usedCap,
  ofCap,
}: {
  usedCap: bigint | null;
  ofCap: bigint | null;
  inventoryName: string;
}) => {
  return (
    <>
      <div
        className="Quantum-Container text-2xs font-bold py-2"
        id="smartassembly-invcapacity"
      >
        <EveLinearBar
          nominator={formatM3(usedCap ?? BigInt(0))}
          denominator={formatM3(ofCap ?? BigInt(0))}
          label={`m3`}
        />
      </div>
    </>
  );
};
export default CapacityView;
