import { converBigIntToDisplayNumber } from "../../utils/displayFormat";
import FloatingAnimatedNumber from "../FloatingAnimatedNumber";

const BalanceModule = ({
  tokenAddress,
  tokenSymbol,
  quantity,
  decimals,
  strAppend = null,
}: {
  tokenAddress: string;
  tokenSymbol: string;
  quantity: bigint;
  decimals: number;
  strAppend: null | string;
}) => {
  const number = converBigIntToDisplayNumber(quantity, decimals);
  return (
    <div className="flex flex-col my-1 w-full p-2">
      <span className="font-bold">
        {strAppend ? `${strAppend} Balances` : `Balances`}
      </span>
      <div className="flex flex-row" style={{ alignSelf: "end" }}>
        <FloatingAnimatedNumber
          wholeNumber={parseInt(number.split(".")[0])}
          decimalWholeNumber={parseInt(number.split(".")[1])}
          duration={1000}
        />
        <span style={{ paddingLeft: "0.5rem" }}>{tokenSymbol}</span>
      </div>
    </div>
  );
};

export default BalanceModule;
