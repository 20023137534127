import memoize from "memoizee";

import { Coords, CoordsWithMeta } from "./types";
import npcGateLocations from "../../assets/formatted.json";

export const getCoords = (): {
  nodes: CoordsWithMeta[];
  links: any[];
  type: string;
  nodesMap: { [key: string]: CoordsWithMeta };
} => {
  const fn = memoize(
    () => {
      console.time("getCoords");

      const coords: CoordsWithMeta[] = npcGateLocations as CoordsWithMeta[];
      const locationCoordsToUse = coords.reduce<CoordsWithMeta[]>(
        (acc, item) => {
          if (
            !(
              item.solarSystemId &&
              item.a_name &&
              item.locationX &&
              item.locationY &&
              item.locationZ
            )
          ) {
            return acc;
          }
          acc.push({
            ...item,
            x: item.locationX,
            y: item.locationY,
            z: item.locationZ,
          });
          return acc;
        },
        []
      );
      //   const locationCoordsScaled = scaleEachTo100(locationCoordsToUse);
      // filter out locations that don't have all the required fields
      const middlePosition = extractMiddleOfCoords(locationCoordsToUse);
      const shiftedCoords = shiftCoordinatesToCenter(
        locationCoordsToUse,
        middlePosition
      );

      const scaledCoords = scaleCoordinatesToRange(shiftedCoords);
      // setInitialFocusedCoords(extractMiddleOfCoords(scaledCoords));
      const nodes = scaledCoords.map((location, idx) => {
        return {
          a_name: location.a_name,
          id: idx,
          solarSystemId: location.solarSystemId,
          name: location.a_name,
          fx: location.x,
          fy: location.y,
          fz: location.z,
          relations: location.relations,
          relationsLength: location.relations.length,
          locationX: location.x,
          locationY: location.y,
          locationZ: location.z,
          x: location.x,
          y: location.y,
          z: location.z,
        };
      });
      const linksMap = new Map<
        string,
        { source: number; /*name: string;*/ target: number }
      >();
      const mapOfNodes = nodes.reduce<Record<string, any>>(
        (acc, curr) => {
          acc.byId[curr.solarSystemId.toString()] = curr;
          acc.byName[curr.name.toLowerCase()] = curr;
          return acc;
        },
        { byId: {}, byName: {} }
      );
      nodes.forEach((node) => {
        node.relations.forEach((rel) => {
          const targetNode = mapOfNodes.byId[rel.solarSystemId.toString()];
          if (!targetNode) {
            return;
          }
          const linkId = `${node.solarSystemId.toString()}-${targetNode.solarSystemId.toString()}`;
          const duplicateLinkId = `${targetNode.solarSystemId.toString()}-${node.solarSystemId.toString()}`;
          if (
            linksMap.has(linkId) ||
            linksMap.has(duplicateLinkId) ||
            node.solarSystemId === targetNode.solarSystemId
          ) {
            return;
          }
          if (!rel.distance) {
            console.error("No distance found for relation", rel);
            return;
          }
          linksMap.set(linkId, {
            source: node.id,
            target: targetNode.id,
            // name: `${node.name} - ${targetNode.name}: ${rel.distance.toString().slice(0, 6)}LY`,
          });
          return;
        });
      });
      const data = {
        nodes,
        links: Array.from(linksMap.values()),
        type: "NPC Gate",
        nodesMap: mapOfNodes.byName,
      };
      console.timeEnd("getCoords");

      return data;
    },
    { maxAge: 60 * 1000 }
  );
  return fn();
};

export const scaleCoordinatesToRange = (
  coords: CoordsWithMeta[],
  scaleTo: number = 250
): CoordsWithMeta[] => {
  const maxX = Math.max(...coords.map((c) => Math.abs(c.x)));
  const maxY = Math.max(...coords.map((c) => Math.abs(c.y)));
  const maxZ = Math.max(...coords.map((c) => Math.abs(c.z)));
  const max = Math.max(maxX, maxY, maxZ); // Find the maximum value to scale proportionally

  const scaleFactorZ = scaleTo / maxZ;
  const scaleFactor = scaleTo / max;

  return coords.map((c) => ({
    ...c,
    x: c.x * scaleFactor,
    y: c.y * scaleFactor,
    z: c.z * scaleFactorZ,
  }));
};

export const noOp = () => {};

export const shiftCoordinatesToCenter = (
  coords: CoordsWithMeta[],
  middle: { x: number; y: number; z: number }
): CoordsWithMeta[] => {
  return coords.map((c) => ({
    ...c,
    x: c.x - middle.x,
    y: c.y - middle.y,
    z: c.z - middle.z,
  }));
};

export const extractMiddleOfCoords = (coords: CoordsWithMeta[]): Coords => {
  const allX = coords.reduce((acc, curr) => acc + curr.x, 0);
  const allY = coords.reduce((acc, curr) => acc + curr.y, 0);
  const allZ = coords.reduce((acc, curr) => acc + curr.z, 0);

  const len = coords.length;

  return {
    x: allX / len,
    y: allY / len,
    z: allZ / len,
  };
};
