import React from "react";
import memoize from "memoizee";
import { PublicClient, WalletClient } from "viem";
import { useNotification } from "@eveworld/contexts";

import UserStorage from "./UserStorage";
import DeployableStorage from "./DeployableStorage";

import { getAssetBalance, getErc20Balance, useEffectOnce } from "../../utils";
import BalanceModule from "./BalanceModule";
import { useMUD } from "../../MUDContext";
import { Severity } from "@eveworld/types";

const TradingModule = ({
  smartAssembly,
  walletClient,
  publicClient,
}: {
  smartAssembly: any;
  walletClient: WalletClient;
  publicClient: PublicClient;
}): JSX.Element => {
  const [balances, setBalances] = React.useState<{
    playerBalance: bigint;
    ssuSystemBalance: bigint;
  }>({
    playerBalance: 0n,
    ssuSystemBalance: 0n,
  });
  const {
    systemCalls: { getItemTradeContractAddress },
  } = useMUD();
  const { notify } = useNotification();
  const tradeAssetSymbol = "EVE";
  const tradeAssetAddress =
    "0x227517bfb7846827831744461fECb1234C66f65a".toLowerCase();
  const tradeAssetDecimals = 18;

  useEffectOnce(() => {
    console.debug("Smart Assembly", smartAssembly);
    // run balance lookup on initial load
    if (!walletClient?.account?.address) {
      throw new Error("No account address found");
    }
    Promise.all([
      getAssetBalance(
        walletClient?.account?.address,
        tradeAssetAddress,
        walletClient,
        publicClient,
        tradeAssetDecimals
      ),
      getItemTradeContractAddress()
        .then(async (itemTradeContractAddress) => {
          return await getAssetBalance(
            itemTradeContractAddress,
            tradeAssetAddress,
            walletClient,
            publicClient,
            tradeAssetDecimals
          )
            .then((d) => d)
            .catch((error) => {
              console.error(error);
            });
        })
        .catch((error) => {
          console.error("failed to get item trade contract address", error);
        }),
    ])
      .then(([userBalance, ssuBalance]) => {
        setBalances({
          ssuSystemBalance: ssuBalance || 0n,
          playerBalance: userBalance || 0n,
        });
      })
      .catch((error) => {
        console.error(error);
      });

    setInterval(() => {
      if (!walletClient?.account?.address) {
        throw new Error("No account address found");
      }
      Promise.all([
        getAssetBalance(
          walletClient?.account?.address,
          tradeAssetAddress,
          walletClient,
          publicClient,
          tradeAssetDecimals
        ),
        getItemTradeContractAddress().then(async (itemTradeContractAddress) => {
          return await getAssetBalance(
            itemTradeContractAddress,
            tradeAssetAddress,
            walletClient,
            publicClient,
            tradeAssetDecimals
          )
            .then((d) => d)
            .catch((error) => {
              console.error(error);
            });
        }),
      ])
        .then(([userBalance, ssuBalance]) => {
          setBalances({
            ssuSystemBalance: ssuBalance || 0n,
            playerBalance: userBalance || 0n,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }, 4000);
  });

  return (
    <div className="flex flex-col w-full">
      <div className="flex align-center w-full" style={{ flex: 1 }}>
        <BalanceModule
          tokenAddress={tradeAssetAddress}
          tokenSymbol={tradeAssetSymbol}
          quantity={balances.playerBalance}
          decimals={tradeAssetDecimals}
          strAppend={"User"}
        />
      </div>
      {/* <div className="flex align-center w-full" style={{ flex: 1 }}>
        <BalanceModule
          tokenAddress={tradeAssetAddress}
          tokenSymbol={tradeAssetSymbol}
          quantity={balances.ssuSystemBalance}
          decimals={tradeAssetDecimals}
          strAppend={"SSU System"}
        />
      </div> */}
      <div className="grid grid-cols-2 w-full" style={{ flex: 1 }}>
        <div className="flex">
          <UserStorage
            smartAssembly={smartAssembly}
            walletClient={walletClient}
            tradeAssetBalance={balances.playerBalance}
            tradeAssetDecimals={tradeAssetDecimals}
            tradeAssetTicker={tradeAssetSymbol}
          />
        </div>
        <div className="flex">
          <DeployableStorage
            smartAssembly={smartAssembly}
            walletClient={walletClient}
            tradeAssetBalance={balances.playerBalance}
            tradeAssetDecimals={tradeAssetDecimals}
            tradeAssetTicker={tradeAssetSymbol}
          />
        </div>
      </div>
    </div>
  );
};

export default TradingModule;
