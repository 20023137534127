import { DEFAULT_DECIMAL_PLACES_TO_DISPLAY } from "../constants";

export const formatDisplayPrice = (
  price: bigint,
  decimals: number = 18,
  maxDecimals: number = DEFAULT_DECIMAL_PLACES_TO_DISPLAY
): string => {
  // Calculate the integer part
  const integerPart = price / BigInt(10 ** decimals);
  const fractionalPart = price % BigInt(10 ** decimals); // get remainder

  const fractionalString = fractionalPart
    .toString()
    .padStart(decimals, "0")
    .slice(0, maxDecimals); // format to 18 decimals
  return `${integerPart}.${fractionalString}`;
};

export const converBigIntToDisplayNumber = (
  quantity: bigint,
  decimals: number,
  maxDecimals: number = DEFAULT_DECIMAL_PLACES_TO_DISPLAY
) => {
  // Calculate the integer part
  const integerPart = quantity / BigInt(10 ** decimals);
  const fractionalPart = quantity % BigInt(10 ** decimals); // get remainder
  const fractionalString = fractionalPart
    .toString()
    .padStart(decimals, "0")
    .slice(0, maxDecimals); // format to 6 decimal places
  return `${integerPart}.${fractionalString}`;
};
