/*
 * Create the system calls that the client can use to ask
 * for changes in the World state (using the System contracts).
 */

import { SetupNetworkResult } from "./setupNetwork";

export type SystemCalls = ReturnType<typeof createSystemCalls>;

export const createSystemCalls = (
  /*
   * The parameter list informs TypeScript that:
   *
   * - The first parameter is expected to be a
   *   SetupNetworkResult, as defined in setupNetwork.ts
   *
   *   Out of this parameter, we care about the following fields:
   *   - worldContract (which comes from getContract, see
   *     https://github.com/latticexyz/mud/blob/main/templates/react/packages/client/src/mud/setupNetwork.ts#L63-L69).
   *
   *   - erc20Contract
   *   - useStore
   *   - tables
   */
  { worldContract, erc20Contract, walletClient }: SetupNetworkResult
) => {
  /*
   * This function is retrieved from the codegen function in contracts/src/codegen/world/IItemTradeSystem.sol
   * And must be used with the skunkworks__ prefix due to namespacing
   */
  const getERC20Data = async (
    smartObjectId: bigint
  ): Promise<{
    tokenAddress: string;
    receiver: string;
    decimals: number;
  }> => {
    const result: Promise<{
      tokenAddress: string;
      receiver: string;
      decimals: number;
    }> = (await worldContract.read.skunkworks__getERC20Data([
      smartObjectId,
    ])) as unknown as Promise<{
      tokenAddress: string;
      receiver: string;
      decimals: number;
    }>;
    return result;
  };

  const registerERC20Token = async (
    smartObjectId: bigint,
    tokenAddress: `0x${string}`,
    receiver: `0x${string}`
  ) => {
    await worldContract.write.skunkworks__registerERC20Token([
      smartObjectId,
      tokenAddress,
      receiver,
    ]);
    return await worldContract.read.skunkworks__getERC20Data([smartObjectId]);
  };

  const updateERC20Receiver = async (
    smartObjectId: bigint,
    receiver: `0x${string}`
  ) => {
    await worldContract.write.skunkworks__updateERC20Receiver([
      smartObjectId,
      receiver,
    ]);
    return await worldContract.read.skunkworks__getERC20Data([smartObjectId]);
  };

  const checkIfAdmin = async (
    smartObjectId: bigint,
    address: `0x${string}`
  ) => {
    return await worldContract.read.skunkworks__isOwner([
      smartObjectId,
      address,
    ]);
  };

  /** ITEM PRICE FUNCTIONS */

  const getItemPrice = async (
    itemId: bigint,
    smartObjectId: bigint,
    type: "purchase" | "sell" | "none"
  ): Promise<bigint | undefined> => {
    if (type === "none") return;
    const fnToCall =
      type === "purchase"
        ? worldContract.read.skunkworks__getPurchaserPrice
        : worldContract.read.skunkworks__getSellerPrice;
    const itemPrice = (await fnToCall([
      itemId,
      smartObjectId,
    ])) as unknown as bigint;
    return itemPrice;
  };

  /** PURCHASE ITEM FUNCTIONS */

  const getErc20Balance = async (address: `0x${string}`): Promise<bigint> => {
    const balance = await erc20Contract.read.balanceOf([address]);
    console.debug("Balance", balance, " for address ", address);
    return balance;
  };

  const getItemTradeContractAddress = async (): Promise<string> => {
    const itemTradeContractAddress =
      (await worldContract.read.skunkworks__getItemTradeContractAddress()) as unknown as Promise<string>;
    return itemTradeContractAddress;
  };

  const purchaseItems = async (
    smartObjectId: bigint,
    itemId: bigint,
    quantity: bigint
  ) => {
    const itemPrice = await getItemPrice(itemId, smartObjectId, "purchase");
    if (!itemPrice)
      return console.error("Unable to retrieve item price for purchase");
    if (Number(itemPrice) == 0) return console.error("Item price not set");

    const itemSellerContractAddress =
      (await worldContract.read.skunkworks__getItemTradeContractAddress()) as unknown as `0x${string}`;
    const approvalAmount = quantity * BigInt(itemPrice);
    // First, approve spend by the contract address
    const approvalProps = [itemSellerContractAddress, BigInt(approvalAmount)];
    await erc20Contract.write.approve(approvalProps);
    // Then, purchase item
    await worldContract.write.skunkworks__purchaseItems([
      smartObjectId,
      BigInt(itemId),
      BigInt(quantity),
    ]);

    return;
  };

  /** SELL ITEM FUNCTIONS */

  /**
   * Sell items
   * @param smartObjectId - The smart object id
   * @param itemId - The item id
   * @param quantity - The quantity to sell
   * @returns
   */
  const sellItems = async (
    smartObjectId: bigint,
    itemId: bigint,
    quantity: bigint
  ) => {
    await worldContract.write.skunkworks__sellItems([
      BigInt(smartObjectId),
      BigInt(itemId),
      BigInt(quantity),
    ]);
    return;
  };

  /**
   *
   * @param smartObjectId
   * @param address
   * @returns
   */
  const collectTokens = async (
    smartObjectId: bigint,
    address: `0x${string}`
  ) => {
    await worldContract.write.skunkworks__collectTokens([smartObjectId]);
    return await getErc20Balance(address);
  };

  return {
    checkIfAdmin,
    registerERC20Token,
    updateERC20Receiver,
    getItemTradeContractAddress,
    purchaseItems,
    collectTokens,
    getItemPrice,
    getERC20Data,
    getErc20Balance,
    sellItems,
  };
};
